<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-02-03 09:01:23
-->
<template>
  <div>
    <el-dialog :title="$t('w_0251')"
               :visible.sync="pwShow"
               :close-on-click-modal="false"
               width="30%"
               center
               :before-close="handleClose">
      <div class="tit"
           v-show="this.whatBank == ''">{{$t('w_0249')}}</div>
      <div class="tit"
           v-show="this.whatBank != ''">{{$t('w_0057')}}</div>
      <!-- <div class="input-box flexbox">
        <div class="code-item"
             :class="codeValue.length == 0 && inputFocus ? 'code-item-active' : ''">{{dianValue[0]}}</div>
        <div class="code-item"
             :class="codeValue.length == 1 && inputFocus ? 'code-item-active' : ''">{{dianValue[1]}}</div>
        <div class="code-item"
             :class="codeValue.length == 2 && inputFocus ? 'code-item-active' : ''">{{dianValue[2]}}</div>
        <div class="code-item"
             :class="codeValue.length == 3 && inputFocus ? 'code-item-active' : ''">{{dianValue[3]}}</div>
        <div class="code-item"
             :class="codeValue.length == 4 && inputFocus ? 'code-item-active' : ''">{{dianValue[4]}}</div>
        <div class="code-item"
             :class="codeValue.length >= 5 && inputFocus ? 'code-item-active' : ''">{{dianValue[5]}}</div>
        <el-input class="input-code"
                  :value="codeValue"
                  ref="input"
                  :maxlength="6"
                  @blur="codeInputBlur"
                  @focus="codeInputFocus"
                  @input="codeInputChange">
        </el-input>
      </div> -->
      <el-input v-model="codeValue"
                type="password"></el-input>
      <div class="btn"
           @click="payPw">{{ $t('w_0248') }}</div>
    </el-dialog>
    <successDialog @successClose="successClose"
                   ref="successDialog"></successDialog>
  </div>
</template>

<script>
import * as api from '@/api/pay.js'
import successDialog from '@/components/successDialog.vue'
export default {
  components: {
    successDialog,
  },
  data() {
    return {
      codeValue: '',
      inputFocus: false,
      pwShow: this.isPw,
      form: {},
      dianValue: [],
      whatBank: '',
      pkCountry: '',
    }
  },
  props: {
    isPw: {
      type: Boolean,
      default: false,
    },
    pwData: '',
    isBank: '',
    businessType: '',
  },
  watch: {
    isPw(val) {
      this.codeValue = ''
      this.pwShow = val
      this.dianValue = []
      // this.$nextTick(() => {
      //   this.$refs.input.focus()
      // })
    },
    isBank(val) {
      this.whatBank = val
    },
    pwData: {
      handler(val) {
        if (val.pkCountry) {
          this.pkCountry = val.pkCountry
        } else {
          this.pkCountry = parseInt(localStorage.getItem('pkCountry'))
        }

        if (!val.specialArea) {
          val.specialArea = val.orderType
        }
        this.form = val
      },
      deep: true,
    },
  },
  methods: {
    payPw() {
      // 非银行卡支付
      if (this.whatBank == '') {
        if (this.form.specialArea == 4) {
          //抽奖
          let obj = {
            payPwd: this.codeValue,
            payNum: this.form.luckydrawData.payNum,
            pkBaseId: this.form.luckydrawData.pkBaseId,
          }
          api.payDrawGift(obj).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.successClose()
            }
          })
        } else {
          //非抽奖
          let obj = Object.assign(
            {},
            {
              orderCode: this.form.orderCode,
              specialArea: this.form.specialArea,
              pkCountry: this.pkCountry,
              pkSettleCountry: this.pkCountry,
              makerSpaceMember: this.form.makerSpaceMember||'',
            },
            { payPwd: this.codeValue }
          )
          let url = ''
          if (
            this.form.specialArea == 1 ||
            this.form.specialArea == 7 ||
            this.form.specialArea == 24
          ) {
            url = api.payRegOrder
          } else {
            url = api.payOthOrder
          }
          url(obj).then((res) => {
            if (res.code == 200) {
              if (
                this.form.specialArea == 1 ||
                this.form.specialArea == 7 ||
                this.form.specialArea == 24
              ) {
                this.$refs.successDialog.showSuccess(res.data)
              } else {
                this.$message({
                  message: res.msg,
                  type: 'success',
                })
                this.successClose()
              }
            }
          })
        }
      } else if (this.whatBank == 'jd') {
        let obj = Object.assign(
          {},
          {
            orderCode: this.form.orderCode,
            bindCode: this.form.bindCode,
            specialArea: this.form.specialArea,
            pkSettleCountry: this.pkCountry,
          },
          { smsCode: this.codeValue }
        )
        api.payConfirmJd(obj).then((res) => {
          if (res.code == 200) {
            if (
              this.form.specialArea == 1 ||
              this.form.specialArea == 7 ||
              this.form.specialArea == 24
            ) {
             
              // this.$refs.successDialog.showSuccess(res.data)
              this.handleClose()
            } else {
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.successClose()
            }
          }
        })
      } else if (this.whatBank == 'hf') {
        let obj = Object.assign(
          {},
          {
            orderCode: this.form.orderCode,
            bindCode: this.form.bindCode,
            specialArea: this.form.specialArea,
            pkSettleCountry: this.pkCountry,
          },
          { smsCode: this.codeValue }
        )
        api.payConfirmHf(obj).then((res) => {
          if (res.code == 200) {
            if (
              this.form.specialArea == 1 ||
              this.form.specialArea == 7 ||
              this.form.specialArea == 24
            ) {
              // this.$refs.successDialog.showSuccess(res.data)
              this.handleClose()
            } else {
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.successClose()
            }
          }
        })
      }else if (this.whatBank == 'tl') {
        let obj = Object.assign(
          {},
          {
            orderCode: this.form.orderCode,
            bindCode: this.form.bindCode,
            specialArea: this.form.specialArea,
            pkSettleCountry: this.pkCountry,
          },
          { smsCode: this.codeValue }
        )
        api.payConfirmTl(obj).then((res) => {
          if (res.code == 200) {
            if (
              this.form.specialArea == 1 ||
              this.form.specialArea == 7 ||
              this.form.specialArea == 24
            ) {
              // this.$refs.successDialog.showSuccess(res.data)
              this.handleClose()
            } else {
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.successClose()
            }
          }
        })
      }
    },
    // 支付全关
    successClose() {
      this.$emit('closePwPay', 1)
    },
    // 只关闭输入密码
    handleClose() {
      this.$emit('closePwPay', 0)
    },
    // 验证码输入框
    codeInputChange(e) {
      this.dianValue = []
      if (e) {
        // 判断输入内容是否为数字
        if (/^\+?[0-9][0-9]*$/.test(e)) {
          this.codeValue = e
          for (let i = 0; i < e.length; i++) {
            this.dianValue.push('●')
          }
        }
      } else {
        this.codeValue = ''
        this.dianValue = []
      }
    },
    // 验证码输入框失去焦点
    codeInputBlur() {
      this.inputFocus = false
    },
    // 验证码输入框获取到焦点
    codeInputFocus() {
      this.inputFocus = true
    },
  },
}
</script>

<style lang="scss" scoped>
.input-box {
  margin-top: 30px;
  position: relative;
}
.input-code {
  position: absolute;
}
.code-item {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border: 1px solid #cccccc;
  margin-right: 10px;
  box-shadow: 0px 0px 10px 0px #fff;
}
.code-item-active {
  box-shadow: 0px 0px 10px 0px #d5251d;
  border: 1px solid #d5251d;
  border-radius: 2px 2px 2px 2px;
  box-sizing: border-box;
}
.tit {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  text-align: center;
  margin: 10px 0;
}
.flexbox {
  display: flex;
  justify-content: center;
}
// 隐藏input
.input-box {
  ::v-deep .el-input__inner {
    width: 100%;
    height: 42px;
    background-color: transparent;
    border: none;
    color: transparent;
  }
}
.btn {
  line-height: 50px;
  width: 180px;
  height: 50px;
  background: #d5251d;
  border-radius: 6px 6px 6px 6px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  margin: 25px auto;
  cursor: pointer;
}
</style>